import React from "react";
import { useNavigate } from "react-router-dom";
import DormanSwag from "../Assets/Images/Dorman-Swag_Lifestyle-Photo_6.jpg";
//import logo from "../Assets/Images/logo.png"
import logo from "../Assets/Images/Dorman-Rewards_logo-wht.png";

const HeroSec = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="heroSec">
        <div className="heroBox">
          <div className="bgOrangeBox">
            <img src={logo} alt="logo" />
            <div
              style={{ backgroundColor: "white", height: "5px", marginBottom: "50px"}}
            />
            <h3>REWARD PROGRAM FOR</h3>
            <h2>AUTOMOTIVE TECHNICIANS</h2>
          </div>
          <div>
            <img src={DormanSwag} alt="AarmyMan" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSec;
