import React from 'react'
import EarnPoints from '../components/EarnPoints'
import HeroSec from '../components/HeroSec'
import Promotion from '../components/Promotion'
import YourPoints from '../components/YourPoints'
import FAQs from "../components/faq";

const LandingPage = () => {
  return (
    <div>
      <div className="container">
        <HeroSec />
        <Promotion />
        <EarnPoints />
        <YourPoints />
      </div>
      <FAQs />
    </div>
  );
}

export default LandingPage