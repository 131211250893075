import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const history = useNavigate();

  const onSubmit = async () => {
    history("/logout");
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((userData) => {})
      .catch(() => {
        history("/login");
      });
  }, []);

  return (
    <div>
      Dashboard
      <br />
      <Button className="signUpBtn themeBtn" onClick={onSubmit}>
        LOGOUT
      </Button>
    </div>
  );
}

export default Dashboard;
