import React, { useState } from "react";

function FAQs() {
  const [accordionState, setAccordionState] = useState({
    accordion1: false,
    accordion2: false,
    accordion3: false,
    accordion4: false,
    accordion5: false,
  });

  const handleAccordionClick = (accordionId) => {
    if (accordionState[accordionId]) {
      setAccordionState((prevAccordionState) => ({
        ...prevAccordionState,
        [accordionId]: false,
      }));
    } else {
      Object.keys(accordionState).forEach((key) => {
        setAccordionState((prevAccordionState) => ({
          ...prevAccordionState,
          [key]: false,
        }));
      });
      setAccordionState((prevAccordionState) => ({
        ...prevAccordionState,
        [accordionId]: true,
      }));
    }
  };

  return (
    <section className="faq">
      <div className="faqs-inner">
        <div className="container">
          <div className="hue_headtital">
            <h1>Dorman Rewards FAQs</h1>
          </div>
          <div className="accordion">
            <div
              className="accordiontonprim wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".5s"
            >
              <button
                id="accordion-button-1"
                aria-expanded={accordionState.accordion1}
                onClick={() => handleAccordionClick("accordion1")}
              >
                <span className="accordion-title">
                  How do I redeem my points?
                </span>
                <span className="icon" aria-hidden="true"></span>
              </button>
              <div
                className="accordion-content"
                style={{
                  display: accordionState.accordion1 ? "block" : "none",
                }}
              >
                <p>
                  To redeem, you can either do so directly through the rewards
                  program dashboard or during checkout. Please note that your
                  points will be deducted immediately, and that once selected
                  you will not be able to change your selection so only choose a
                  Rewards discount when you are ready to checkout. Only 1
                  rewards offer/points can be redeemed in a purchase.
                </p>
              </div>
            </div>
            <div
              className="accordiontonprim wow fadeInUp"
              data-wow-duration="1.1s"
              data-wow-delay=".6s"
            >
              <button
                id="accordion-button-2"
                aria-expanded={accordionState.accordion2}
                onClick={() => handleAccordionClick("accordion2")}
              >
                <span className="accordion-title">
                  What can I use my points on?{" "}
                </span>
                <span className="icon" aria-hidden="true"></span>
              </button>
              <div
                className="accordion-content"
                style={{
                  display: accordionState.accordion2 ? "block" : "none",
                }}
              >
                <p>
                  You can use your points for purchase or discounts on your
                  order on the{" "}
                  <a href="https://dorman-rewards.com">Dorman-Rewards.com</a>{" "}
                  site. Rewards discounts can be used on any item purchase, but
                  cannot be applied to shipping fees, taxes or gift cards.
                </p>
              </div>
            </div>
            <div
              className="accordiontonprim wow fadeInUp"
              data-wow-duration="1.2s"
              data-wow-delay=".7s"
            >
              <button
                id="accordion-button-3"
                aria-expanded={accordionState.accordion3}
                onClick={() => handleAccordionClick("accordion3")}
              >
                <span className="accordion-title">
                  Can I apply my points to orders I have already placed?
                </span>
                <span className="icon" aria-hidden="true"></span>
              </button>
              <div
                className="accordion-content"
                style={{
                  display: accordionState.accordion3 ? "block" : "none",
                }}
              >
                <p>
                  No, the Rewards discount must be surrendered and applied to
                  your order at check out and cannot be applied to already
                  placed orders.
                </p>
              </div>
            </div>
            <div
              className="accordiontonprim wow fadeInUp"
              data-wow-duration="1.3s"
              data-wow-delay=".8s"
            >
              <button
                id="accordion-button-4"
                aria-expanded={accordionState.accordion4}
                onClick={() => handleAccordionClick("accordion4")}
              >
                <span className="accordion-title">Do my points expire?</span>
                <span className="icon" aria-hidden="true"></span>
              </button>
              <div
                className="accordion-content"
                style={{
                  display: accordionState.accordion4 ? "block" : "none",
                }}
              >
                <p>
                  Points are valid for 12 months from the date of the last
                  qualifying purchase. Each qualifying purchase will
                  automatically extend the expiration date of all unused points
                  for 12 months from the month of the qualifying purchase.
                </p>
              </div>
            </div>
            <div
              className="accordiontonprim wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".5s"
            >
              <button
                id="accordion-button-5"
                aria-expanded={accordionState.accordion5}
                onClick={() => handleAccordionClick("accordion5")}
              >
                <span className="accordion-title">
                  How can I get assistance with my account-related queries ?
                </span>
                <span className="icon" aria-hidden="true"></span>
              </button>
              <div
                className="accordion-content"
                style={{
                  display: accordionState.accordion5 ? "block" : "none",
                }}
              >
                <p>
                  If you have any questions or concerns regarding your account,
                  please reach out to our support team at rewards@dormanproducts.com.
                  We'll be happy to assist you with any account-related queries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQs;
