import React from "react";
import { useNavigate } from "react-router-dom";

const Promotion = () => {
  const navigate = useNavigate();

  return (
    <div className="earnPoints">
      <div className="earnPointsBg">
        <div className="bgrewardsimage">
          <p>
            Dorman Rewards is a first-in-class rewards program where
            professional technicians can earn points for new product ideas,
            product feedback, training, Dorman parts purchases and more.
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={() => navigate("/signup")}>SIGN UP NOW!</button>
            <p style={{ marginTop: 80, textAlign: "center" }}>
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => navigate("/login")}
              >
                <b>Login</b>
              </span>{" "}
              if you already have an account.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotion;
