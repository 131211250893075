import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Auth } from "aws-amplify";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("This is not a valid email")
    .required("Email is a required field"),
  password: yup.string().required("Password is a required field"),
});

const LoginForm = () => {
  const [show, setShow] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [showVerifyErrorMessage, setshowVerifyErrorMessage] = useState(false);
  const history = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    if (data) {
      try {
        setLoader(true);
        const response = await Auth.signIn(data.email, data.password);
        if (response) {
          setLoader(true);
          setSuccessMessage(
            "Login successful. Redirecting to your rewards dashboard..."
          );
          seterrorMessage("");
          window.location.replace(
            `${process.env.REACT_APP_LOGIN_REDIRECT}?partner_id=${process.env.REACT_APP_PARTNER_ID}&zrl_token=${response.signInUserSession.idToken.jwtToken}`
          );
        } else {
          seterrorMessage(
            "There was an error authenticating you into the Dorman rewards account. Please try again later."
          );
        }
      } catch (error) {
        setLoader(false);
        if (
          error.name === "UserNotFoundException" ||
          error.name === "PasswordResetRequiredException"
        ) {
          setshowVerifyErrorMessage(false);
          seterrorMessage(
            "The login credentials are incorrect. Please try again"
          );
          setShow(true);
        } else if (error.name === "NotAuthorizedException") {
          setshowVerifyErrorMessage(false);
          seterrorMessage(
            "The login credentials are incorrect. Please try again"
          );
          setShow(true);
        } else if (error.name === "UserNotConfirmedException") {
          setshowVerifyErrorMessage(true);
          setShow(false);
        } else if (error.name === "LimitExceededException") {
          setshowVerifyErrorMessage(false);
          seterrorMessage(error.message);
          setShow(true);
        }
      }
      reset();
    }
  };

  return (
    <div className="formWrap">
      {loader && <Loading />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formControl">
          <label>Email Address</label>
          <Form.Control {...register("email")} />
          {errors.email && (
            <p className="errorMessage">{errors.email.message}</p>
          )}
        </div>
        <div className="formControl">
          <label>Password</label>
          <Form.Control type="password" {...register("password")} />
          {errors.password && (
            <p className="errorMessage">{errors.password.message}</p>
          )}
        </div>
        {successMessage && <p className="confirmMessage">{successMessage}</p>}
        {show && errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {showVerifyErrorMessage && (
          <p className="errorMessage">
            Your email address is unverified. You will need to{" "}
            <Link to="/verify-email">verify your email address</Link> before you
            can proceed with logging in to your account.
          </p>
        )}
        <Button
          className="signUpBtn themeBtn"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          LOGIN
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
