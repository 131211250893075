import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Loading from "../components/Loading";

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("This is not a valid email")
    .required("Email is a required field"),
});

const ResetPassword = () => {
  const history = useNavigate();

  const [show, setShow] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [loader, setLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showVerifyErrorMessage, setshowVerifyErrorMessage] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    if (data) {
      try {
        setLoader(true);
        await Auth.forgotPassword(data.email);
        history(`/reset/`, { state: { email: data.email } });
        seterrorMessage("");
        setLoader(false);
      } catch (error) {
        if (error.name === "InvalidParameterException") {
          setLoader(false);
          setshowVerifyErrorMessage(true);
          setShow(false);
        } else if (error.name === "UserNotFoundException") {
          seterrorMessage("");
          history(`/reset/`, { state: { email: data.email } });
        } else {
          setLoader(false);
          seterrorMessage(
            "There was an error while sending you the verification code. Please try again later."
          );
          setshowVerifyErrorMessage(false);
          setShow(true);
        }
      }
      reset();
    }
  };

  return (
    <div className="Formbg">
      {loader && <Loading />}
      <div className="signUpTopBox"><div className="signUpTopBoxTitle"><h4>Rewards Program For Automotive Technicians</h4></div></div>
      <Container>
        <div className="formBox">
          <div className="formWrap">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h2>RESET PASSWORD</h2>
              <div className={"formControl" + (errors.email ? " error" : "")}>
                <label>Email Address*</label>
                <Form.Control {...register("email")} />
                {errors.email && (
                  <p className="errorMessage">{errors.email.message}</p>
                )}
              </div>
              {successMessage && (
                <p className="confirmMessage">{successMessage}</p>
              )}
              {show && errorMessage && (
                <p className="errorMessage">{errorMessage}</p>
              )}
              {showVerifyErrorMessage && (
                <p className="errorMessage">
                  Your email address is unverified. You will need to{" "}
                  <Link to="/verify-email">verify your email address</Link>{" "}
                  before you can proceed with resetting your password.
                </p>
              )}
              <Button
                className="signUpBtn themeBtn"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                {"RESET"}
              </Button>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
