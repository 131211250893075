import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
// import HTMLReactParser from 'html-react-parser';
import { Link } from "react-router-dom";

const VerifySchema = yup.object().shape({
  email: yup.string().email("This is not a valid email").required("Email is a required field"),
});

const VerifyEmail = () => {
  const history = useNavigate();
  const [show, setShow] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [loader, setLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showLoginErrorMessage, setshowLoginErrorMessage] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(VerifySchema),
    mode: "onChange",
  });


  const onSubmit = async (data) => {
    if (data) {
      setLoader(true);
      try {
        const result = await Auth.resendSignUp(data.email);
        if (result) {
          setTimeout(() => {
            history(`/confirm/`, { state: { email: data.email } });
          }, 3000);
          setSuccessMessage("Sending the verification code...");
          setShow(true);
        }
      } catch (error) {
        if (error.name === "InvalidParameterException") {
          setLoader(false);
          setshowLoginErrorMessage(true);
          seterrorMessage("");
          setShow(false);
        } else if (error.name === "UserNotFoundException") {
          setLoader(false);
          setshowLoginErrorMessage(false);
          history(`/confirm/`, { state: { email: data.email } });
          setShow(false);
        } else {
          setLoader(false);
          setshowLoginErrorMessage(false);
          seterrorMessage("There was an error while sending you the verification code. Please try again later.");
          setShow(true);
        }
      }
      reset();
    }
  };

  return (
    <div className="Formbg">
      <div className="signUpTopBox">
        <div className="signUpTopBoxTitle"><h4>Rewards Program For Automotive Technicians</h4></div>
      </div>
      {loader && <Loading />}
      <Container>
        <div className="formBox">
          <div className="formWrap">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h2>VERIFY YOUR EMAIL</h2>
              <div className={"formControl" + (errors.email ? " error" : "")}>
                <label>Email Address*</label>
                <Form.Control {...register("email")} />
                {errors.email && <p className="errorMessage">{errors.email.message}</p>}
              </div>
              {showLoginErrorMessage && <p className="errorMessage">Your email address is already verified. <Link to="/login">Please login.</Link></p>}
              {successMessage && <p className="confirmMessage">{successMessage}</p>}
              {show && errorMessage && <p className="errorMessage">{errorMessage}</p>}
              <Button className="signUpBtn themeBtn" type="submit" onClick={handleSubmit(onSubmit)}>
                VERIFY
              </Button>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default VerifyEmail;
