import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import yourpoints from "../utils/YourPoints.json";

const YourPoints = () => {
  const navigate = useNavigate();
  const [pointsData, setPointsData] = useState(6);

  return (
    <div id="rewardsSection" className="yourPoints">
      <h2 className="earnPointsText">Earn rewards by redeeming your points.</h2>
      <div className="earnPointsBoxWrap">
        <ul>
          {yourpoints?.slice(0, pointsData).map((item, index) => (
            <li key={index}>
              {console.log("item", item)}
              <img src={require(`../Assets/Images/${item.imgUrl}`)} alt="" />
              <div className="earnPointsContent">
                <div className="titleWrap">
                  <h4>{item.title}</h4>
                  <h6>{item.subTitle}</h6>
                </div>
                <p>{item.pointsDetail}</p>
                <button onClick={() => navigate("/login")}>
                  {item.buttonText}
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default YourPoints;
