import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import SignUpForm from "../components/SignUpForm";

const SignUp = () => {
  return (
    <div className="Formbg">
      <div className="signUpTopBox">
        <div className="signUpTopBoxTitle"><h4>Rewards Program For Automotive Technicians</h4></div>
      </div>
      <Container>
        <div className="formBox">
          <SignUpForm />
          <p className="haveAccount">
            Have an account? <Link to="/login">Sign in</Link>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default SignUp;
