import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import ReactCodeInput from "react-verification-code-input";
import Loading from "../components/Loading";

function ConfirmEmail() {
  const history = useNavigate();
  const [code, setCode] = useState();
  const [show, setShow] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState(
    "If the email address you entered is present in our system, you will receive an email with a verification code. Please enter the verification code to verify your email address."
  );
  const [fromSignupMessage, setFromSignupMessage] = useState(
    "We have sent you an email with a verification code. Please enter the code to verify your email address."
  );
  const {
    state: { email, fromPage }
  } = useLocation();

  const onSubmit = () => {
    try {
      setLoader(true);
      Auth.confirmSignUp(email, code)
        .then(() => {
          setSuccessMessage("Email verification successful. Redirecting you to login page...");
          seterrorMessage("");
          setTimeout(() => {
            history("/login");
          }, 2000);
        })
        .catch((error) => {
          setLoader(false);
          console.log("Error confirming sign up", error);
          seterrorMessage("The verification code is incorrect. Please try again.");
          setShow(true);
        });
    } catch (error) {
      setLoader(false);
      seterrorMessage("There was an error while verifying your email address. Please try again later.");
      setShow(true);
      console.log("Error confirming sign up", error);
    }
  };

  return (
    <div>
      <div className="Formbg">
        <div className="signUpTopBox">
          <div className="signUpTopBoxTitle">
            <h4>Rewards Program For Automotive Technicians</h4>
          </div>
        </div>
        {loader && <Loading />}
        <Container>
          <div className="formBox">
            <div className="formWrap">
              <h2>Confirm Email</h2>
              {message && fromPage !== "signup" && (
                <p className="confirmMessage">{message}</p>
              )}
              {message && fromPage === "signup" && (
                <p className="confirmMessage">{fromSignupMessage}</p>
              )}
              <div className="formControl">
                <label>Email Address</label>
                <Form.Control
                  style={{ pointerEvents: "none" }}
                  type="email"
                  value={email}
                />
              </div>
              <div className="formControl">
                <label>Verification Code</label>
                <ReactCodeInput
                  onChange={(e) => setCode(e)}
                  className="verificationInput"
                />
              </div>
              {successMessage && (
                <p className="confirmMessage">{successMessage}</p>
              )}
              {show && errorMessage && (
                <p className="errorMessage">{errorMessage}</p>
              )}
              <Button className="signUpBtn themeBtn" onClick={onSubmit}>
                Confirm
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ConfirmEmail;
