import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

function Logout() {
  const history = useNavigate();

  const onSubmit = async () => {
    try {
      await Auth.signOut();
//       window.location.replace(
//         `${process.env.REACT_APP_LOGIN_REDIRECT}?partner_id=${process.env.REACT_APP_PARTNER_ID}&zrl_token=logout`
//       );
        window.location.replace("/")
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  useEffect(() => {
    onSubmit();
  }, []);

  return (
    <div className="Formbg">
      <Container>
        <h5 className="logoutText">
          Logging you out of the Dorman rewards account...
        </h5>
      </Container>
    </div>
  );
}

export default Logout;
