import React from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
//import Brand from "../Assets/Images/Dormanrewardslogo.png";
import Brand from "../Assets/Images/Dorman-Rewards_logo-color.png";
const index = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  return (
    <div className="headerWrap">
      <Container>
        <a href="/" className="brandSec">
          <img src={Brand} alt="Brand" />
        </a>
      </Container>
      <div class="borderWrap">
        {isLoginPage && (
          <Link to="/login" className="loginText">
            LOGIN
          </Link>
        )}
      </div>
    </div>
  );
};

export default index;
