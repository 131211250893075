import React from "react";
import Amplify from "aws-amplify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import SignUp from "./Pages/Signup";
import ConfirmEmail from "./Pages/ConfirmEmail";
import ResetPassword from "./Pages/ResetPassword";
import VerifyEmail from "./Pages/VerifyEmail";
import Reset from "./Pages/Reset";
import Success from "./Pages/Success";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/Scss/Main.scss";
import awsconfig from "./aws-exports";
import Logout from "./Pages/Logout";
import LandingPage from "./Pages/LandingPage";

Amplify.configure(awsconfig);

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/success" element={<Success />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/confirm" element={<ConfirmEmail />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
