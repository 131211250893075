import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoginForm from "../components/LoginForm";

function Login() {
  return (
    <div className="Formbg">
      <div className="signUpTopBox">
        <div className="signUpTopBoxTitle">
          <h4>Rewards Program For Automotive Technicians</h4>
        </div>
      </div>
      <Container>
        <div className="formBox">
          <h2>LOGIN</h2>
          <LoginForm />
          <p>
            <Link to="/reset-password" className="text-black">
              Reset Password
            </Link>
          </p>
          <p className="haveAccount">
            Don't have an account? <Link to="/signup">Sign up</Link>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default Login;
